@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira poppins, Droid poppins, Helvetica Neue,
    poppins-serif;
  -webkit-tap-highlight-color: transparent;
}

.qr-container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.vaccinate-goal-div:after {
  content: "";
  @apply absolute left-[80%] top-[-7px] block h-3 w-3 -translate-x-0.5 -rotate-45 border-r-[1px] border-t-[1px] border-disabled bg-off-white;
}

.publisher-img {
  height: 24px !important;
  position: relative !important;
  width: unset !important;
}

@media (min-width: 768px) {
  .publisher-img {
    height: 32px !important;
  }
}

.publisher-img-div > span {
  position: unset !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
}

.trending-news-stacks .react-horizontal-scrolling-menu--item:last-child {
  margin-right: 0px;
}

.trending-news-stacks .react-horizontal-scrolling-menu--item:first-child {
  @apply mr-0;
  @apply lg:mr-[24px];
}

.react-horizontal-scrolling-menu--item:first-child {
  margin-left: 0px;
}

.news-stacks-carousel .react-horizontal-scrolling-menu--scroll-container {
  width: initial;
  margin-left: auto;
  margin-right: auto;
}

.trending-news-stacks .react-horizontal-scrolling-menu--item {
  @apply mr-[16px];
  @apply lg:mr-[24px];
}

.search-trending-stacks .react-horizontal-scrolling-menu--item {
  margin-right: -30px;
}

.react-horizontal-scrolling-menu--item {
  margin-left: 50px;
}

.trending-news-stacks .react-horizontal-scrolling-menu--item {
  margin-left: 0px;
}

.react-horizontal-scrolling-menu--item:last-child {
  margin-right: 50px;
}

/* rich news stacks styles */
.rich-news-stack-text {
  @apply font-poppins;
  word-wrap: break-word;
}

.rich-news-stack-text p {
  @apply text-text-dark;
}

.rich-news-stack-text h1 {
  @apply text-2xl md:text-3xl;
}

.rich-news-stack-text h2 {
  @apply text-xl md:text-2xl;
}

.rich-news-stack-text h3 {
  @apply text-lg md:text-xl;
}

.rich-news-stack-text a {
  @apply text-primary2 underline;
}

.rich-news-stack-text ul {
  @apply text-text-dark;
  @apply list-disc;
}

.rich-news-stack-text ol {
  @apply text-text-dark;
  @apply list-decimal;
  @apply ml-8;
}

.rich-news-stack-text li {
  @apply my-2;
  @apply leading-6;
}

.rich-news-stack-embed.twitter {
  @apply flex justify-center;
}

.rich-news-stack-embed.instagram iframe.instagram-media {
  margin: 0 auto !important;
}

.rich-news-stack-embed.facebook_video iframe {
  margin: 0 auto !important;
}

.rich-news-stack-embed.facebook_post iframe {
  margin: 0 auto !important;
}

.rich-block-image {
  @apply my-8;
}

@media screen and (min-width: 580px) {
  .splide__pagination li button {
    width: 30px;
    height: 30px;
    background-color: #bfcdff;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 580px) {
  .splide__pagination li button {
    width: 10px;
    height: 10px;
    background-color: #bfcdff;
    margin-bottom: 10px;
  }
}

.splide__pagination__page.is-active {
  background-color: #3453dc !important;
}

@media screen and (min-width: 1024px) {
  .splide__pagination--ttb {
    margin-right: 200px !important;
  }
}

.news-content p,
.news-content h1,
.news-content h2,
.news-content h3 {
  @apply mb-4;
}

.youtube-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.youtube-container iframe,
.youtube-container object,
.youtube-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.control-dots {
  opacity: 0 !important;
}

.publisher-carousel-img {
  height: 56px !important;
  width: 96px !important;
  object-fit: cover !important;
}

.ge15-stacks:first-child {
  margin-left: 5px;
}

.running-emote {
  transform: scale(-1, 1);
  width: fit-content;
}

.stripe-lines {
  background-image: repeating-linear-gradient(
    135deg,
    #807f98 4%,
    transparent 4%,
    transparent 12%,
    #807f98 12%,
    #807f98 16%,
    transparent 16%
  );
}

.stripe-lines-container > * {
  background-image: repeating-linear-gradient(
    135deg,
    #807f98 4%,
    transparent 4%,
    transparent 12%,
    #807f98 12%,
    #807f98 16%,
    transparent 16%
  );
}

.tooltip {
  @apply invisible absolute;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0 2px 2px);
  transform: translateY(0.6rem);
}

.has-tooltip:hover .tooltip {
  @apply visible z-10;
}

.box.arrow-top {
  margin-top: 40px;
}

.box.arrow-top:after {
  content: " ";
  position: absolute;
  left: 10px;
  top: -15px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 15px solid white;
}

.blink_dot {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

video {
  clip-path: inset(1px 1px);
}

.qr-container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.usp-section:hover .first-p {
  color: #3453dc; /* Replace with your actual primary color */
}

.iframe-content {
  display: hidden;
}

.rich-news-stack-text ol > li,
.rich-news-stack-text ul > li {
  list-style-type: none;
}
.rich-news-stack-text ul > li::before {
  content: "\2022";
}
.rich-news-stack-text ul[data-checked="true"],
.rich-news-stack-text ul[data-checked="false"] {
  pointer-events: none;
}
.rich-news-stack-text ul[data-checked="true"] > li *,
.rich-news-stack-text ul[data-checked="false"] > li * {
  pointer-events: all;
}
.rich-news-stack-text ul[data-checked="true"] > li::before,
.rich-news-stack-text ul[data-checked="false"] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.rich-news-stack-text ul[data-checked="true"] > li::before {
  content: "\2611";
}
.rich-news-stack-text ul[data-checked="false"] > li::before {
  content: "\2610";
}
.rich-news-stack-text li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.rich-news-stack-text li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.rich-news-stack-text li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.rich-news-stack-text ol li:not(.ql-direction-rtl),
.rich-news-stack-text ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.rich-news-stack-text ol li.ql-direction-rtl,
.rich-news-stack-text ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.rich-news-stack-text ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.rich-news-stack-text ol li:before {
  content: counter(list-0, decimal) ". ";
}
.rich-news-stack-text ol li.ql-indent-1 {
  counter-increment: list-1;
}
.rich-news-stack-text ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}
.rich-news-stack-text ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-2 {
  counter-increment: list-2;
}
.rich-news-stack-text ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}
.rich-news-stack-text ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-3 {
  counter-increment: list-3;
}
.rich-news-stack-text ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}
.rich-news-stack-text ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-4 {
  counter-increment: list-4;
}
.rich-news-stack-text ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}
.rich-news-stack-text ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-5 {
  counter-increment: list-5;
}
.rich-news-stack-text ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}
.rich-news-stack-text ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-6 {
  counter-increment: list-6;
}
.rich-news-stack-text ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}
.rich-news-stack-text ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-7 {
  counter-increment: list-7;
}
.rich-news-stack-text ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}
.rich-news-stack-text ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.rich-news-stack-text ol li.ql-indent-8 {
  counter-increment: list-8;
}
.rich-news-stack-text ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}
.rich-news-stack-text ol li.ql-indent-8 {
  counter-reset: list-9;
}
.rich-news-stack-text ol li.ql-indent-9 {
  counter-increment: list-9;
}
.rich-news-stack-text ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.rich-news-stack-text .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 1em;
}
.rich-news-stack-text li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 2.5em;
}
.rich-news-stack-text .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 1em;
}
.rich-news-stack-text li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 2.5em;
}
.rich-news-stack-text .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 2em;
}
.rich-news-stack-text li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 3.5em;
}
.rich-news-stack-text .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 2em;
}
.rich-news-stack-text li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 3.5em;
}
.rich-news-stack-text .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.rich-news-stack-text li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.rich-news-stack-text .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.rich-news-stack-text li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.rich-news-stack-text .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 4em;
}
.rich-news-stack-text li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 5.5em;
}
.rich-news-stack-text .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 4em;
}
.rich-news-stack-text li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 5.5em;
}
.rich-news-stack-text .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 5em;
}
.rich-news-stack-text li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 6.5em;
}
.rich-news-stack-text .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 5em;
}
.rich-news-stack-text li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 6.5em;
}
.rich-news-stack-text .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.rich-news-stack-text li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.rich-news-stack-text .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.rich-news-stack-text li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.rich-news-stack-text .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 7em;
}
.rich-news-stack-text li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 8.5em;
}
.rich-news-stack-text .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 7em;
}
.rich-news-stack-text li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 8.5em;
}
.rich-news-stack-text .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 8em;
}
.rich-news-stack-text li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 9.5em;
}
.rich-news-stack-text .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 8em;
}
.rich-news-stack-text li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 9.5em;
}
.rich-news-stack-text .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.rich-news-stack-text li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.rich-news-stack-text .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.rich-news-stack-text li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.rich-news-stack-text li.ql-direction-rtl {
  padding-right: 1.5em;
}

.rich-news-stack-text li.ql-direction-rtl > .ql-ui:before {
  margin-left: 0.3em;
  margin-right: -1.5em;
  text-align: left;
}

.rich-news-stack-section {
  scroll-margin-top: 50px;
}

/* width */
.scrollbar::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f6f8fd;
  border-radius: 5px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #3453dc;
  border-radius: 5px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #98afff;
}

.feed-block-news + .feed-block-news {
  @apply !my-4 border-t border-light-background pt-4;
}

.feed-block-text > .feed-block-text-container h1 {
  @apply font-sen text-2xl font-bold;
}

.hero-card-carousel-container {
  position: relative;
  overflow: hidden;
}

.hero-card-carousel {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Adjust transition duration as needed */
  overflow: scroll;
}

/* .slide {
  flex: 0 0 auto;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #fff; /* Adjust button color as needed */
  font-size: 24px;
  padding: 0 10px;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

.prev-button:disabled,
.next-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.fading-background {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
}

.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

/* gradient */
.yt-lite::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 80px;
  height: 80px;
  z-index: 1;
  opacity: 0.9;
  border: none;
  color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%20fill=%22white%22%20aria-hidden=%22true%22%3E%3Cpath%20d=%22M6.3%202.841A1.5%201.5%200%20004%204.11V15.89a1.5%201.5%200%20002.3%201.269l9.344-5.89a1.5%201.5%200%20000-2.538L6.3%202.84z%22%3E%3C/path%3E%3C/svg%3E");
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  opacity: 1;
}
/* play button triangle */
/* .yt-lite > .lty-playbtn:before {
  content: "";
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}
 */
.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.feed-block-text {
  @apply !mt-2;
}

.feed-block-text h2 {
  @apply font-bold;
}

.feed-block-shorts_carousel {
  @apply !mt-2;
}

.rich-news-stack-text py-8 {
  @apply mx-2;
}
